import React from 'react';
import { NavigationLink } from '@economist/design-system/dist/umd/common';
import { UseUIConfig } from '../../../providers/UIConfigProvider/UseUIConfig';
import { deleteSheets } from '../../util/office-document';
import PropTypes from 'prop-types';

const LinksBottom = ({ isLoading, restartWorkflow }) => {
  const config = UseUIConfig();
  const UIConfig = config && config.UIConfig;

  const disabledProps = {
    disabled: isLoading
  };

  const resetFn = () => {
    deleteSheets();
    restartWorkflow();
  };

  return (
    <div className='eiu-fdp-info bottom-links'>
      <div>
        {UIConfig && (
          <NavigationLink
            onClick={e => {
              e.preventDefault();
              deleteSheets();
            }}
            text='Delete all sheets'
            icon='remove'
            href='#'
            {...disabledProps}
          />
        )}
      </div>
      <div>
        <NavigationLink onClick={resetFn} text='Reset forecast' href='#' {...disabledProps} />
      </div>
    </div>
  );
};

LinksBottom.propTypes = {
  isLoading: PropTypes.bool,
  restartWorkflow: PropTypes.func
};

export default LinksBottom;
