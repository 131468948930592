/* global Excel */

import { updateSheetRenders, getSheetRenders, addSheetRenderOperation, wrapWithExcel } from './office-document';
import { FixRowColumnDesign } from './worksheet-design-config';
import { convertToMonthlySheetHeaderFormat, convertToQuarterlySheetHeaderFormat } from './date_utils';
import globalVars from '../../styles/dsvars';

export const writeDataSheetToExcel = async (context, response, config) => {
  const resKeys = Object.keys(response[0]);
  let resFreq = 'annual';
  if (resKeys.includes('quarterly')) {
    resFreq = 'quarterly';
  } else if (resKeys.includes('monthly')) {
    resFreq = 'monthly';
  }
  const index = config.ui.sheets.findIndex(o => o.type == resFreq);
  const sheetName = config.ui.sheets[index].displayName;
  let sheet = context.workbook.worksheets.getItemOrNullObject(sheetName);
  await context.sync();

  if (!sheet.isNullObject) {
    sheet.getUsedRange().clear();
    addSheetRenderOperation(sheetName, 'values');
  } else {
    sheet = context.workbook.worksheets.add(sheetName);
    const sheetRenders = getSheetRenders();
    updateSheetRenders([
      ...sheetRenders,
      {
        sheetName: sheetName,
        operations: ['values'],
        nextRenderAt: new Date().getTime()
      }
    ]);
  }

  let currentRow = 0;
  const startColumn = 0;

  const headerRow = ['Series Name', 'URNs'];
  const periods = response[0][resFreq].period.map(item => {
    if (resFreq === 'quarterly') {
      return convertToQuarterlySheetHeaderFormat(item);
    } else if (resFreq === 'monthly') {
      return convertToMonthlySheetHeaderFormat(item);
    } else {
      return item; //annual or error cases
    }
  });
  headerRow.push(...periods);

  const headerRange = sheet.getRangeByIndexes(currentRow, startColumn, 1, headerRow.length);
  const periodsRange = sheet.getRangeByIndexes(currentRow, startColumn + 2, 1, headerRow.length - 2);
  headerRange.values = [headerRow];
  headerRange.format.font.bold = true;
  headerRange.format.fill.color = globalVars['--ds-color-london-85'];
  periodsRange.format.horizontalAlignment = Excel.HorizontalAlignment.right;
  currentRow++;

  const allValues = [];
  const boldRanges = [];
  const underlineRanges = [];

  config.ui.sheets[index].sections.forEach(section => {
    if (section.title) {
      allValues.push([section.title, ...new Array(headerRow.length - 1).fill('')]);
      boldRanges.push(sheet.getRangeByIndexes(currentRow, startColumn, 1, 1));
      currentRow++;
    }
    if (section.subtitle) {
      allValues.push([section.subtitle, ...new Array(headerRow.length - 1).fill('')]);
      underlineRanges.push(sheet.getRangeByIndexes(currentRow, startColumn, 1, 1));
      currentRow++;
    }
    section.series.forEach(item => {
      const rowData = new Array(headerRow.length).fill('-');
      rowData[0] = item.displayName;
      rowData[1] = item.seriesId.value === 'NO_URN_FOUND' ? '' : item.seriesId.value;
      const responseSeries = response[0][resFreq].series.find(r => r.seriesId === item.seriesId.value);
      if (responseSeries) {
        responseSeries.values.forEach((value, index) => {
          rowData[2 + index] = value || '-';
        });
      }
      allValues.push(rowData);
      currentRow++;
    });
    allValues.push(new Array(headerRow.length).fill(''));
    currentRow++;
  });

  const dataRange = sheet.getRangeByIndexes(1, startColumn, allValues.length, headerRow.length);
  dataRange.values = allValues;
  boldRanges.forEach(range => (range.format.font.bold = true));
  underlineRanges.forEach(range => (range.format.font.underline = 'Single'));
  const dataCellsRange = sheet.getRangeByIndexes(1, startColumn + 2, allValues.length, headerRow.length - 2);
  dataCellsRange.numberFormat = [['0.00']];
  const usedRange = sheet.getUsedRange();
  usedRange.format.autofitColumns();
  FixRowColumnDesign(sheet, 1, 1, 'C1:AH100');

  await context.sync();
  console.log('Successfully written data sheet');
};

export const writeDataAQMToNewSheets = async (apiResponse, UIConfig) => {
  try {
    await wrapWithExcel(writeDataSheetToExcel, apiResponse, UIConfig);
  } catch (error) {
    console.error('Error writing data to the new sheet:', error);
  }
};
