import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { UseUserInfo } from '../../../providers/UserInfoProvider/UseUserInfo';
import { baseApplicationHostname } from '../../../config/urlConstants';
import DataRetrieval from '../DataRetrieval/DataRetrieval';
import Header from '../Header/Header';
import UserDetails from '../UserDetails/UserDetails';
import SplashScreen from '../SplashScreen/SplashScreen';
// import UiConfigGenerator from '../UiConfigGenerator/UiConfigGenerator';
// import UiConfigGenerator from '../UiConfigGenerator/UiConfigGeneratorDataAQM';
import Api from '../../../api/api';
import { UseActionState } from '../../../providers/ActionStateProvider/UseActionState';
import DialogTrigger from '../DialogTrigger/DialogTrigger';

const Home = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const { applicationActionState, setApplicationActionState } = UseActionState();
  const [isSetupInProgress, setIsSetupInProgress] = useState();

  const { userDetails, setUserDetails } = UseUserInfo();

  const tryRefreshToken = async currentOktaAuth => {
    console.group();
    if (currentOktaAuth && currentOktaAuth != null) {
      try {
        const renewToken = await currentOktaAuth.token.renewTokens();
        currentOktaAuth.tokenManager.setTokens(renewToken);
      } catch (e) {
        console.log("Tried to refresh the user's token and failed with exception", e);
      }
    }
    console.groupEnd();
  };

  const attemptTokenRenewal = async () => {
    try {
      const token = await oktaAuth.tokenManager.get('accessToken');
      if (token && token.expiresAt) {
        const expiresInSeconds = token.expiresAt - Math.round(Date.now() / 1000);
        if (expiresInSeconds < 300) {
          tryRefreshToken(oktaAuth);
          setUserInfo(null);
        }
      }
    } catch (error) {
      console.error('Error renewing tokens:', error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(attemptTokenRenewal, 60000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!authState) {
      return;
    }
    if (!authState.isAuthenticated) {
      tryRefreshToken(oktaAuth);
      setUserInfo(null);
    } else {
      oktaAuth
        .getUser()
        .then(async info => {
          setUserInfo(info);
          setUserDetails(info);
          setIsSetupInProgress(true);
          if (!applicationActionState.featureFlags) {
            await Api.setUp()
              .then(async response => {
                if (response) {
                  setIsSetupInProgress(false);
                  console.log(response.data);
                  setApplicationActionState({
                    ...applicationActionState,
                    ...{
                      isOpenWorkbookButtonEnabled: true,
                      featureFlags: response.data.featureFlags
                    }
                  });
                }
              })
              .catch(err => {
                console.log('An error occurred during the forecast setup', err);
                setApplicationActionState({
                  ...applicationActionState,
                  ...{
                    isOpenWorkbookButtonEnabled: false,
                    isSetupError: 'Something went wrong while setting up your forecast. Please try again'
                  }
                });
              });
          }
        })
        .catch(err => {
          console.log('Error Fetching user Info', authState, err);
        });
    }
  }, [authState, oktaAuth]);

  function processMessage(arg) {
    const messageFromDialog = JSON.parse(arg.message);
    localStorage.setItem('okta-token-storage', JSON.stringify(messageFromDialog.authState));
    oktaAuth.authStateManager.updateAuthState(messageFromDialog.authState);
  }

  const env = baseApplicationHostname();
  const urlLogin = `${env}/excel/login/popup`;

  return (
    <div id='home'>
      <div>
        {authState?.isAuthenticated && !userInfo && (
          <div className='excel-landing-screen_indicator'>
            <SplashScreen header='EIU Forecasts' />
          </div>
        )}
        {!authState?.isAuthenticated && !userInfo && !userDetails && (
          <div className='eiu-fdp-info eiu-fdp-info-login'>
            <Header />
            <h1>EIU Forecasts</h1>
            <p className='eiu-fdp-info-text'>
              Please log in using your EIU OKTA credentials. If you are unable to access your account please contact the
              Service Desk.
            </p>
            <DialogTrigger processMessage={processMessage} actionText='Log in' url={urlLogin} vertical />
          </div>
        )}
        {/* {authState?.isAuthenticated && <UiConfigGenerator />} */}
        {authState?.isAuthenticated && userInfo && (
          <>
            <UserDetails />
            <DataRetrieval isSetupInProgress={isSetupInProgress} />
          </>
        )}
      </div>
    </div>
  );
};

export default Home;
