import React, { useEffect, useState } from 'react';
import { Rule, NavigationLink } from '@economist/design-system/dist/umd/common';
import {
  insertBulkText,
  insertStyle,
  createSheet,
  getSheetRenders,
  clearSheetRender,
  removeOnWorksheetChangedEvent,
  removeOnFormatChangedEvent,
  addOnFormatChanged,
  setActiveSheet,
  deleteSheets
} from '../../util/office-document';
import { UseUIConfig } from '../../../providers/UIConfigProvider/UseUIConfig';
import { UseResidualChange } from '../../../providers/ResidualChangeProvider/UseResidualChange';
import { UseActionState } from '../../../providers/ActionStateProvider/UseActionState';
import { DataModifierByConfigForSheet, deepCopyObject } from '../../util/DataModifier';
import { calcuateFrequencyRange } from '../../util/FrequencyCalculation';
import Error from '../Error/Error';
import ResidualUpdate from '../ResidualUpdate/ResidualUpdate';
import SplashScreen from '../SplashScreen/SplashScreen';
import ForecastApproval from '../ForecastApproval/ForecastApproval';
import Api from '../../../api/api';
import { processCsvData } from '../../util/uiConfigHelper';
import CountrySelector from '../CountrySelector/CountrySelector';
import LinksBottom from '../LinksBottom/LinksBottom';
import { DataAQMConfig } from '../UiConfigGenerator/DataAQMConfig';
import PropTypes from 'prop-types';
import { writeDataAQMToNewSheets } from '../../util/DataModifierAQM';
import { standardModellingDoc } from '../../util/constants';

export const generateDataRequestsForSheets = (config, setUIConfig, isMetaDataNeeded) => {
  const requestMapping = {};
  config.models.forEach(currentModel => {
    const mapping = deepCopyObject(currentModel.mapping.mapping);
    requestMapping[currentModel.model] = mapping;
  });

  const sheetDataRequests = [];
  const includeMetadata = isMetaDataNeeded;

  config.ui.sheets.forEach(sheet => {
    const annualFrequencies = sheet.frequencies.annual;
    const quarterlyFrequencies = sheet.frequencies.quarterly;

    let requests = [];
    let annualSeriesIds = [];
    let quarterlySeriesIds = [];
    const version = sheet.type === 'forecast_checker' ? [config.branch, config.meta_data.snapshot_id] : [config.branch];

    if (annualFrequencies) {
      const calculatedAnnualDateRange = calcuateFrequencyRange(annualFrequencies, 'annual');
      requests.push({
        frequency: 'annual',
        toDate: calculatedAnnualDateRange.toDate,
        fromDate: calculatedAnnualDateRange.fromDate,
        seriesUrns: annualSeriesIds
      });
    }

    if (quarterlyFrequencies) {
      const calcuatedQuarterlyDateRange = calcuateFrequencyRange(quarterlyFrequencies, 'quarterly');
      requests.push({
        frequency: 'quarterly',
        toDate: calcuatedQuarterlyDateRange.toDate,
        fromDate: calcuatedQuarterlyDateRange.fromDate,
        seriesUrns: quarterlySeriesIds
      });
    }
    sheet.sections.forEach(section => {
      section.series.forEach(series => {
        if (series.seriesId) {
          if (series.seriesId.annual && series.seriesId.annual !== 'NO_URN_FOUND') {
            if (annualFrequencies) {
              annualSeriesIds.push(series.seriesId.annual);
            }
            // else {
            //   console.error(
            //     'found annual seriesId when no annual frequency was present in UISheetconfig',
            //     sheet.displayName,
            //     series.seriesId.annual
            //   );
            // }
          }
          if (series.seriesId.quarterly && series.seriesId.quarterly !== 'NO_URN_FOUND') {
            if (quarterlyFrequencies) {
              quarterlySeriesIds.push(series.seriesId.quarterly);
            }
            // else {
            //   console.error(
            //     'found quarterly seriesId when no quarterly frequency was present in UISheetconfig',
            //     sheet.displayName,
            //     series.seriesId.quarterly
            //   );
            // }
          }
        } else {
          // the below check allows us to see if model requested from UI config is available in model mappings
          if (section.model.length > 0 && requestMapping[section.model[0]]) {
            const quarterlyMappingSeries = requestMapping[section.model[0]].find(x => x.name === series.name);
            const annualMappingSeries = requestMapping[section.model[0]].find(x => x.name === `yy_${series.name}`);
            if (quarterlyMappingSeries && quarterlyMappingSeries.urn) {
              series.seriesId = {
                quarterly: quarterlyMappingSeries.urn
              };
              quarterlySeriesIds.push(quarterlyMappingSeries.urn);
            }
            if (annualMappingSeries && annualMappingSeries.urn) {
              series.seriesId = {
                annual: annualMappingSeries.urn
              };
              annualSeriesIds.push(annualMappingSeries.urn);
            }
          }
        }
      });
    });

    sheetDataRequests.push({
      version,
      includeMetadata,
      searchData: requests.map(requestItem => {
        return {
          ...requestItem,
          seriesUrns: [...new Set(requestItem.seriesUrns)]
        };
      }),
      sheetName: sheet.displayName
    });
  });

  setUIConfig(config);

  return sheetDataRequests;
};

export const buildURNMapper = data => {
  const map = {};
  if (data?.length && data[0].annual?.series?.length) {
    data[0].annual.series.forEach(item => {
      // done to skip the usage of values & seriesId passed to drop from item. Lint rule was not picking the case.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      map[item.seriesId] = (({ values, seriesId, ...o }) => o)(item);
    });
  }
  if (data?.length && data[0].quarterly?.series?.length) {
    data[0].quarterly.series.forEach(item => {
      // done to skip the usage of values & seriesId passed to drop from item. Lint rule was not picking the case.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      map[item.seriesId] = (({ values, seriesId, ...o }) => o)(item);
    });
  }
  return map;
};

const DataRetrieval = ({ isSetupInProgress }) => {
  const [isError, setIsError] = useState(false);
  const [isInvokeAllError, setInvokeAllError] = useState(false);
  const [makeSearchApiCall, setMakeSearchApiCall] = useState(false);
  const [makeLatestSeriesPeriodsCall, setMakeLatestSeriesPeriodsCall] = useState(false);
  const [debugRunId, setDebugRunId] = useState(null);
  const [debugApprovalStatus, setDebugApprovalStatus] = useState({
    readyStatus: null,
    approveStatus: null
  });
  const {
    UIConfig,
    getUIConfig,
    setUIConfig,
    UIConfigErrorMessage,
    calculatedSheetData,
    updateSheetData,
    latestSeriesPeriods,
    setLatestSeriesPeriods,
    addedRenderCheckInterval,
    setAddedRenderCheckInterval
  } = UseUIConfig();
  const approvalTime = UIConfig?.meta_data?.approved_at;
  const [isUIConfigLoading, setIsUIConfigLoading] = useState(false);
  const [isRestartWorkflowLoading, setIsRestartWorkflowLoading] = useState(false);
  const [dataMap, setDataMap] = useState({});
  const [fullDataMap, setFullDataMap] = useState({});
  const [isDataFetchLoading, setIsDataFetchLoading] = useState(false);
  const { allResidualUpdates, addResidualUpdate } = UseResidualChange();
  const { applicationActionState, setApplicationActionState } = UseActionState();
  const [seriesIdIndex, setSeriesIdIndex] = useState();
  const { unprocessedResidualUpdates, inFlightResidualUpdates } = UseResidualChange();
  const [DWBranchName, setDWBranchName] = useState(null);
  const [canForecastBeSubmitted, setCanForecastBeSubmitted] = useState(false);

  const [isLoading, setIsLoading] = useState(
    isSetupInProgress || isUIConfigLoading || isDataFetchLoading || isRestartWorkflowLoading
  );

  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

  const pollForRenders = () => {
    if (addedRenderCheckInterval) {
      return;
    }
    setAddedRenderCheckInterval(true);
    setInterval(async () => {
      const currentTime = new Date().getTime();
      const sheetRenders = getSheetRenders();
      const sheetsToRender = sheetRenders.filter(
        sheet => sheet.operations.length > 0 && sheet.nextRenderAt < currentTime && sheet.operations.includes('values')
      );
      if (sheetsToRender.length > 0) {
        sheetsToRender.forEach(sheet => {
          redrawSheet(sheet.sheetName);
          clearSheetRender(sheet.sheetName);
        });
      }
    }, 1000);
  };

  const handleConfigRetrieval = async (selectedForecast, branch) => {
    if (makeSearchApiCall) {
      handleDataRetrieval(true);
    } else {
      const startTime = new Date().getTime();
      setIsUIConfigLoading(true);
      const defaultMode = applicationActionState?.featureFlags?.IsReviewerWorkflowEnabled
        ? applicationActionState?.forecastConfiguration?.currentMode
        : '';
      await Api.init(
        selectedForecast,
        'forecast',
        applicationActionState?.forecastConfiguration?.currentMode || defaultMode,
        branch
      )
        .then(async response => {
          if (response && response.status == 200 && response.data) {
            setCanForecastBeSubmitted(response.data.canCreatePullRequest || false);
            const branchName = encodeURIComponent(response.data.forecastGitBranch || '');
            setDWBranchName(response?.data?.forecastDWBranch);
            await setApplicationActionState({
              ...applicationActionState,
              gitBranch: encodeURIComponent(response.data.forecastGitBranch),
              dwBranch: encodeURIComponent(response.data.forecastDWBranch)
            });
            await sleep(10);
            await getUIConfig(selectedForecast, 'forecast', branchName);
            const data = response.data;
            const runId = data?.workflow_run_info?.run_id;
            if (!runId && data?.success) {
              setModelInvokedSuccessfullyState();
            }
            if (runId) {
              setDebugRunId(runId);
              let previousCallCompleted = true;
              const polling = setInterval(async () => {
                if (!previousCallCompleted) return;
                previousCallCompleted = false;
                await Api.forecastRunStatus(runId, applicationActionState.forecastConfiguration.countryCode)
                  .then(async response => {
                    if (response && response.status == 200 && response.data) {
                      const runStatus = response.data;
                      if (runStatus.workflow_run_info.run_msg === 'RUNNING') {
                        console.log('Continue polling the run status is: ', runStatus);
                      } else if (runStatus.workflow_run_info.run_msg === 'SUCCEEDED') {
                        clearInterval(polling);
                        setModelInvokedSuccessfullyState();
                      } else {
                        setIsUIConfigLoading(false);
                        setInvokeAllError(true);
                        clearInterval(polling);
                      }
                    } else {
                      setIsUIConfigLoading(false);
                      throw 'The forecast run status retrieval failed';
                    }
                  })
                  .catch(err => {
                    setIsError(err);
                  })
                  .finally(() => {
                    previousCallCompleted = true;
                  });
              }, 1500);
            }
          } else {
            setIsUIConfigLoading(false);
            throw 'Forecast Initialization Failed';
          }
        })
        .catch(err => {
          setIsUIConfigLoading(false);
          setIsDataFetchLoading(false);
          setIsError(err);
          setApplicationActionState({
            ...applicationActionState,
            ...{
              taskPaneActions: {
                isOperationAllowed: true
              }
            }
          });
        });
      const endTime = new Date().getTime();
      console.log(`Peformance log: The function handleConfigRetrieval took ${endTime - startTime} to complete`);
    }
  };

  const setModelInvokedSuccessfullyState = () => {
    setIsUIConfigLoading(false);
    setMakeLatestSeriesPeriodsCall(true);
    setApplicationActionState({
      ...applicationActionState,
      ...{
        getForecastConfig: {
          isOperationAllowed: false
        },
        updateResiduals: {
          isOperationAllowed: false
        },
        forecastReadyForReview: {
          isOperationAllowed: false
        },
        forecastApproved: {
          isOperationAllowed: false
        },
        taskPaneActions: {
          isOperationAllowed: false
        }
      }
    });
  };

  const handleLatestSeriesPeriodsCall = async () => {
    await Api.getLatestSeriesPeriods(applicationActionState.forecastConfiguration.countryCode, 'forecast')
      .then(async response => {
        setMakeLatestSeriesPeriodsCall(false);
        if (response && response.status == 200 && response.data) {
          setLatestSeriesPeriods(response.data);
          try {
            var cleanedJudgementRequest = processJudgements(UIConfig, response);
            if (
              cleanedJudgementRequest.judgementModels.length > 0 &&
              !(applicationActionState?.forecastConfiguration?.currentMode === 'read')
            ) {
              await Api.judgementCleanup(
                applicationActionState.forecastConfiguration.countryCode,
                'forecast',
                cleanedJudgementRequest
              ).then(async cleanedJudgmentResponse => {
                if (cleanedJudgmentResponse && cleanedJudgmentResponse.data.length > 0) {
                  cleanedJudgmentResponse.data.forEach(async judgementModel => {
                    await UIConfig.models.forEach(async uiModel => {
                      if (uiModel.residuals.file == judgementModel.file) {
                        uiModel.residuals.residuals = await processCsvData(judgementModel.residuals);
                      }
                    });
                  });
                  setUIConfig(UIConfig);
                }
              });
            }
          } catch (error) {
            console.error('Error during judgement cleanup', error);
          }
          setMakeSearchApiCall(true);
          return;
        } else {
          throw 'Latest series periods API failed or did not have correct data';
        }
      })
      .catch(e => {
        console.warn('Failed retrieval for latest series periods', e);
        setMakeLatestSeriesPeriodsCall(false);
        setFailedDataRetrievalState();
      });
  };

  const processJudgements = (UIConfig, response) => {
    const results = [];

    for (const uiModel of UIConfig.models) {
      const dataList = [];
      const judgements = uiModel.residuals.residuals;
      const modelResponse = { model: uiModel.model };

      judgements.forEach(judgement => {
        var seriesName = judgement.name.replace('_res', '');
        const startPeriod =
          response.data.modelSeriesLatestPeriods[
            `${applicationActionState.forecastConfiguration.countryCode}.` + uiModel.model
          ][seriesName];
        if (startPeriod) {
          const [startYear, startQuarter] = startPeriod.split('-Q');

          // Annual
          if (startYear && !startQuarter) {
            const judgementPeriod = judgement.date;
            if (judgementPeriod <= startYear) {
              dataList.push({ name: judgement.name, period: judgement.date });
            }
          }
          // Annual + Quarter
          if (startYear && startQuarter) {
            const judgementPeriod = judgement.date;
            const [judgementStartYear, judgementStartQuarter] = judgementPeriod.split('-Q');
            if (judgementStartYear <= startYear && judgementStartQuarter <= startQuarter) {
              dataList.push({ name: judgement.name, period: judgement.date });
            }
          }
        }
      });
      if (dataList.length > 0) {
        modelResponse.judgements = dataList;
        results.push(modelResponse);
      }
    }

    return { judgementModels: results };
  };

  const handleDataRetrieval = async updateStyling => {
    const isMetaDataNeeded = Object.keys(fullDataMap).length > 0 ? false : true;
    const startTime = new Date().getTime();
    // at this point the UI config will be populated
    setIsDataFetchLoading(true);
    setMakeSearchApiCall(false);

    let sheetDataPointsRequests = [];
    try {
      sheetDataPointsRequests = generateDataRequestsForSheets(UIConfig, setUIConfig, isMetaDataNeeded);
    } catch (e) {
      console.warn('handleDataRetrieval failed', e);
    }
    setIsError(null);
    await Promise.all(
      sheetDataPointsRequests.map(async (sheetDataPoints, sheetIndex) => {
        const isNewSheet = await createSheet(sheetDataPoints.sheetName);
        const requestDataForSheet = { ...sheetDataPoints };
        const sheetUIConfig = UIConfig.ui.sheets[sheetIndex];
        const versionName = requestDataForSheet.version;

        let sheetConfigModels = [];
        const sheetSections = sheetUIConfig.sections;
        sheetSections.forEach(section => {
          sheetConfigModels.push(...section.model);
        });

        const sheetModels = UIConfig.models.filter(model => sheetConfigModels.includes(model.model));

        await Api.getForecastData(requestDataForSheet)
          .then(async response => {
            if (response && response.status == 200 && response.data) {
              const sheetMetaData = isMetaDataNeeded ? buildURNMapper(response.data) : fullDataMap;
              const formattedData = DataModifierByConfigForSheet(
                response,
                sheetUIConfig,
                sheetModels,
                allResidualUpdates,
                latestSeriesPeriods,
                versionName,
                sheetMetaData,
                applicationActionState.forecastConfiguration.countryCode
              );
              // console.log(`Calculated data for sheet ${sheetUIConfig.displayName}`, formattedData);
              updateSheetData(sheetUIConfig.displayName, formattedData);
              await processResponseAndWriteSheet(formattedData, sheetUIConfig, updateStyling, isNewSheet);
              setDataMap(sheetMetaData);
            } else {
              throw `The forecast data retrieval failed for ${sheetUIConfig.displayName}`;
            }
          })
          .catch(e => {
            console.warn(e);
            setFailedDataRetrievalState();
          });
      })
    ).then(async () => {
      setIsDataFetchLoading(false);
      if (isMetaDataNeeded) {
        await setActiveSheet(UIConfig?.ui?.sheets[0]?.displayName);
      }
      if (DWBranchName && applicationActionState?.forecastConfiguration?.currentMode === 'read') {
        await Api.deleteWarehouseBranch(encodeURIComponent(DWBranchName));
      }
      if (canForecastBeSubmitted) {
        setApplicationActionState(o => ({
          ...o,
          ...{
            forecastReadyForReview: {
              isOperationAllowed: true
            }
          }
        }));
      }
    });
    const endTime = new Date().getTime();
    console.log(`Peformance log: The function handleDataRetrieval took ${endTime - startTime} to complete`);
  };

  let seriesIdMap = {};

  const redrawSheet = sheetName => {
    const sheetUiConfig = UIConfig?.ui?.sheets?.find(x => x.displayName === sheetName);

    //TODO: EIUFDP-2330 see if we still need to update styling with locked sheet styling
    processResponseAndWriteSheet(calculatedSheetData[sheetName], sheetUiConfig, true);
  };

  const processResponseAndWriteSheet = async (formattedData, sheetUIConfig, updateStyling, isNewSheetInput = false) => {
    const startTime = new Date().getTime();

    seriesIdMap[formattedData.sheetName] = formattedData.seriesIdList;
    setSeriesIdIndex(seriesIdMap);
    let isNewSheet;
    if (!isNewSheetInput) {
      isNewSheet = await createSheet(formattedData.sheetName);
    }

    await removeOnFormatChangedEvent(formattedData.sheetName, isNewSheet);

    const sheetRenderOperations = getSheetRenders();
    const hasUserStylingChange = sheetRenderOperations
      .find(sheetRender => sheetRender.sheetName === formattedData.sheetName)
      ?.operations.includes('style');

    if (updateStyling || hasUserStylingChange) {
      await removeOnWorksheetChangedEvent(formattedData.sheetName);
      // await clearSheet(formattedData.sheetName);
    }

    await insertBulkText(formattedData.printData, formattedData.sheetName);

    const distinctModelCodes = [];
    sheetUIConfig.sections.forEach(item => {
      item.model.forEach(modelCode => {
        if (!distinctModelCodes.includes(modelCode)) {
          distinctModelCodes.push(modelCode);
        }
      });
    });

    const judgementsforSheet = distinctModelCodes.map(code => formattedData?.modelJudgementValues[code]);
    if (updateStyling || isNewSheetInput || hasUserStylingChange) {
      //OnFormatChange gets re-added in insertStyle

      await insertStyle(
        formattedData,
        sheetUIConfig,
        [], //Implement in EIUFDP-2035 to handle groups across frequencies
        addResidualUpdate,
        setApplicationActionState,
        judgementsforSheet ?? {},
        approvalTime,
        applicationActionState.forecastConfiguration.currentMode
      );
    } else {
      // console.log('adding sheet format change instead of applying full styling');
      await addOnFormatChanged(formattedData.sheetName);
    }

    setApplicationActionState({
      ...applicationActionState,
      ...{
        getForecastConfig: {
          isOperationAllowed: false
        },
        updateResiduals: {
          isOperationAllowed: false
        },
        forecastReadyForReview: {
          isOperationAllowed: false
        },
        forecastApproved: {
          isOperationAllowed: false
        },
        taskPaneActions: {
          isOperationAllowed: true
        }
      }
    });
    const endTime = new Date().getTime();
    console.log(`Peformance log: The function processResponseAndWriteSheet took ${endTime - startTime} to complete`);
  };

  useEffect(() => {
    if (UIConfig && makeSearchApiCall) {
      handleDataRetrieval(true);
    }
  }, [UIConfig, makeSearchApiCall]);

  useEffect(() => {
    if (makeLatestSeriesPeriodsCall) {
      handleLatestSeriesPeriodsCall();
    }
  }, [makeLatestSeriesPeriodsCall]);

  const openForcast = () => {
    location.reload();
  };

  const restartWorkflow = async () => {
    setIsRestartWorkflowLoading(true);
    setApplicationActionState({
      ...applicationActionState,
      ...{
        getForecastConfig: {
          isOperationAllowed: false
        },
        updateResiduals: {
          isOperationAllowed: false
        },
        forecastReadyForReview: {
          isOperationAllowed: false
        },
        forecastApproved: {
          isOperationAllowed: false
        },
        taskPaneActions: {
          isOperationAllowed: false
        }
      }
    });
    Api.cleanUp(applicationActionState.forecastConfiguration.countryCode, 'forecast')
      .then(() => {
        setIsRestartWorkflowLoading(false);
        location.reload();
      })
      .catch(error => {
        console.log(error);
        setIsRestartWorkflowLoading(false);
        location.reload();
      });
  };

  useEffect(() => {
    if (UIConfig) {
      pollForRenders();
    }
  }, [UIConfig]);

  useEffect(() => {
    setFullDataMap({
      ...fullDataMap,
      ...dataMap
    });
  }, [dataMap]);

  const setFailedDataRetrievalState = () => {
    setApplicationActionState({
      ...applicationActionState,
      ...{
        getForecastConfig: {
          isOperationAllowed: true
        },
        updateResiduals: {
          isOperationAllowed: false
        },
        forecastReadyForReview: {
          isOperationAllowed: false
        },
        forecastApproved: {
          isOperationAllowed: false
        },
        taskPaneActions: {
          isOperationAllowed: true
        }
      }
    });
  };

  const getCurrentForecastDate = () => {
    const date = new Date();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    return `${month} ${year}`;
  };

  useEffect(() => {
    setIsLoading(
      isSetupInProgress ||
        isUIConfigLoading ||
        isDataFetchLoading ||
        isRestartWorkflowLoading ||
        !!unprocessedResidualUpdates?.length ||
        !!inFlightResidualUpdates?.length
    );
  }, [
    isSetupInProgress,
    isUIConfigLoading,
    isDataFetchLoading,
    isRestartWorkflowLoading,
    unprocessedResidualUpdates,
    inFlightResidualUpdates
  ]);

  const getAQMData = () => {
    setIsDataFetchLoading(true);
    const sheetDataRequests = [];
    const includeMetadata = false;
    const AQMConfig = DataAQMConfig(applicationActionState.forecastConfiguration.countryCode);
    AQMConfig.ui.sheets.forEach(sheet => {
      const version = [UIConfig.branch];
      const frequency = sheet.type;
      const calculatedDateRange = calcuateFrequencyRange(sheet.daterange, frequency);
      const fromDate = calculatedDateRange.fromDate;
      const toDate = calculatedDateRange.toDate;
      const sheetName = sheet.displayName;
      const seriesUrns = [];
      let requests = [];
      sheet.sections.forEach(section => {
        section.series.forEach(series => {
          if (series.seriesId) {
            if (series.seriesId.value && series.seriesId.value !== 'NO_URN_FOUND') {
              seriesUrns.push(series.seriesId.value);
            }
          }
        });
      });
      requests.push({
        frequency,
        toDate,
        fromDate,
        seriesUrns
      });
      sheetDataRequests.push({
        version,
        includeMetadata,
        sheetName,
        searchData: requests.map(requestItem => {
          return {
            ...requestItem,
            seriesUrns: [...new Set(requestItem.seriesUrns)]
          };
        })
      });
    });
    sheetDataRequests.forEach(el => {
      Api.getForecastData(el).then(async response => {
        if (response && response.status == 200 && response.data) {
          await writeDataAQMToNewSheets(response.data, AQMConfig);
          setIsDataFetchLoading(false);
        } else {
          setIsDataFetchLoading(false);
          throw `The forecast data retrieval failed for`;
        }
      });
    });
  };

  const modellingDoc = applicationActionState?.featureFlags?.IsReviewerWorkflowEnabled
    ? UIConfig?.ui?.modelDocumentation
    : standardModellingDoc;

  return (
    <div>
      {/* Step 1: UI Config & Data is loading */}
      {isUIConfigLoading || isDataFetchLoading || isRestartWorkflowLoading ? (
        <SplashScreen header='Forecast is loading' />
      ) : (
        <div className='eiu-fdp-taskpane'>
          {applicationActionState.getForecastConfig.isOperationAllowed ? (
            <CountrySelector initLoadForecast={handleConfigRetrieval} />
          ) : (
            <>
              {(applicationActionState.taskPaneActions.isOperationAllowed || UIConfigErrorMessage || isError) && (
                <div className='eiu-fdp-info'>
                  <h1>
                    {applicationActionState.forecastConfiguration.countryName} {getCurrentForecastDate()} -{' '}
                    {applicationActionState.forecastConfiguration.currentMode === 'read'
                      ? 'read-only'
                      : applicationActionState.forecastConfiguration.forecastState}
                  </h1>
                  <div>
                    <NavigationLink
                      href={modellingDoc}
                      text='Model documentation'
                      target='_blank'
                      disabled={!modellingDoc}
                    />
                  </div>
                  <div>
                    <NavigationLink href='#' text='Update data' disabled />
                  </div>
                  <div>
                    <NavigationLink
                      href='#'
                      text='Open forecast'
                      onClick={e => {
                        e.preventDefault();
                        deleteSheets();
                        openForcast();
                      }}
                    />
                  </div>
                  <div>
                    <NavigationLink
                      href='#'
                      text='See data A, Q, M'
                      onClick={e => {
                        e.preventDefault();
                        getAQMData();
                      }}
                      disabled={!applicationActionState?.featureFlags?.IsReviewerWorkflowEnabled}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
      {/* Step 2: Judgment Updates */}
      <ResidualUpdate handleDataRetrieval={handleDataRetrieval} seriesIdIndex={seriesIdIndex} />

      {/* Step 3: Forecast setup and approval process */}
      {!isLoading && (
        <ForecastApproval setDebugApprovalStatus={setDebugApprovalStatus} debugApprovalStatus={debugApprovalStatus} />
      )}
      <Rule />
      <div className='eiu-fdp-debuging-info-container'>
        <p className='eiu-fdp-debugging-info'>Debugging info</p>
        {/* Step 1 DEBUG: UI Config & Data is loading */}
        {(isUIConfigLoading || isDataFetchLoading) && (
          <Error message='Please wait while the data is fetched and loaded into the sheet.' />
        )}
        {UIConfigErrorMessage && <Error message={UIConfigErrorMessage} />}
        {isError && <Error message={isError} />}
        {isInvokeAllError && (
          <Error message={`Run All Model call failed during polling. Contact admin for ${debugRunId}.`} />
        )}

        {/* Step 2 DEBUG: Judgment Updates */}
        {!!unprocessedResidualUpdates?.length && (
          <p className='eiu-fdp-debugging-info'>({unprocessedResidualUpdates?.length}) Judgements queued for update</p>
        )}
        {!!inFlightResidualUpdates?.length && (
          <p className='eiu-fdp-debugging-info'>({inFlightResidualUpdates?.length}) Judgements being updated</p>
        )}

        {/* Step 2 DEBUG: Forecast setup and approval process */}
        {debugApprovalStatus.readyStatus && <p className='eiu-fdp-debugging-info'>{debugApprovalStatus.readyStatus}</p>}
        {debugApprovalStatus.approveStatus && (
          <p className='eiu-fdp-debugging-info'>{debugApprovalStatus.approveStatus}</p>
        )}
        <LinksBottom isLoading={isLoading} restartWorkflow={restartWorkflow} />
      </div>
    </div>
  );
};

DataRetrieval.propTypes = {
  isSetupInProgress: PropTypes.bool
};

export default DataRetrieval;
